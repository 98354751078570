import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axxxx from '../../assets/images/axxxx.png';
import Input from '@material-ui/core/Input';
import Moment from 'moment';
import SignPadPlus from '../../lib/signPadPlus';

import Toast from "antd-mobile/lib/toast";
import axios from "axios";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';


import Button from '@material-ui/core/Button';
import { Upload, message, Icon } from 'antd';

// 引入antd 时间组件
import 'moment/locale/zh-cn';
import 'antd/dist/antd.css';
import '../../assets/css/border.css';

import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { Modal as ModalAnt, Radio as RadioAnt } from "antd";


import { commitAddFollowupImage, updateFollowupSign } from "../../redux/actions/custom_action";
import Api from '../../redux/api/api';



const BeautyFormControlLabel = withStyles({
    root: {
        margin: 0,
    },
    label: {
        fontSize: '1rem',
        marginLeft: '-1.8vw',
        color: 'black',
        fontWeight: 395,
    },
})(FormControlLabel);

const useStyles = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 14,
        height: 14,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#00C9E4',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 14,
            height: 14,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#00C9E4',
        },
    },

});

const GreenCheckbox = withStyles({
    root: {
        color: "#00C9E4",
        "&$checked": {
            color: "#00C9E4"
        }
    },
    checked: {}
})(props => <Checkbox color="default" {...props} />);

function StyledRadio(props) {
    const classes = useStyles();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

//首次随访
class FirstFollowUpHistoryDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: {},
            patientTreatTypeId: 0,
            patientTypeId: 0,
            jhjCheckResultId: 0,
            sputumCheckResultId: 0,
            fileList: [{ uid: '-1' }],
            picList: [],
            firstFollowUpTime: Moment(new Date(), 'YYYY年MM月DD日'), //首次访视时间
            userId: localStorage.getItem("userId"),
            pic: '',
            patientId: 0,
            noticeId: 0,
            followupType: 0,
            followupTypeOther: '',
            issue: '',
            issueOthers: '',
            useMethod: 0,
            drugType: 0,
            directType: 0,
            aloneLive: 0,
            cloud: 0,
            smoke: 0,
            drink: 0,
            smokeDay: 0,
            drinkDay: 0,
            nextSmoke: 0,
            nextDrink: 0,
            drugPlace: '',
            drugTimeStr: Moment(new Date()),
            nextDrugTimeStr: Moment(new Date()).add(30, 'days').format('YYYY年MM月DD日'),
            nextFollowUpTime: null,
            drugFrequency: '',
            cardSkill: 1,
            drugSkill: 1,
            issueSkill: 1,
            irregularHarm: 1,
            dealReact: 1,
            review: 1,
            takingMedicine: 1,
            livingHabit: 1,
            contactCheck: 1,

            nextTimeYear: '',
            nextTimeMonth: '',
            nextTimeDay: '',

            actualTimeYear: '',
            actualTimeMonth: '',
            actualTimeDay: '',

            drugTimeYear: '',
            drugTimeMonth: '',
            drugTimeDay: '',

            nextDrugTimeYear: '',
            nextDrugTimeMonth: '',
            nextDrugTimeDay: '',

            manageDoctor: '',

            resistMethod: '',
            doctorId: '',
            doctorName: '',

            open: false,
            vertical: 'top',
            horizontal: 'center',
            warnMsg: '',
            firstArrayCheck: [],
            drugTypeArrayCheck: [],

            hideOther: 1,
            suggestion: '',
            removeVisible: false,
        };

        this.handleNavBack = this.handleNavBack.bind(this);

    }

    componentDidMount() {
        this.state.detail = this.props.location.state.detail
        const history = this.state.detail;

        // console.log("history detail == " +JSON.stringify(history))

        this.state.followUpTime = history.followUpTime

        this.setState({
            id: history.id,
            patientId: history.patientId,
            patientName: history.patientName, //患者姓名
            patientNumber: history.patientNumber, //登记号
            //followUpTime : history.followUpTime == null ? '' : Moment(history.followUpTime).format("YYYY年MM月DD日"), //首次随访时间
            followupType: history.followUpType,//随访地点
            followupTypeOther: history.followupTypeOther,
            patientTypeId: history.patientTypeId, //诊断结果
            patientTreatTypeId: history.patientTreatTypeId, //患者类型
            jhjCheckResultId: history.jhjCheckResultId,
            sputumCheckResultId: history.sputumCheckResultId, //耐药情况
            issue: history.issue,//症状及体征
            firstArrayCheck: history.issue.split(","),
            issueOthers: history.issueOthers, //症状及体征其他
            resistMethod: history.resistMethod, //化疗方案
            useMethod: history.useMethod, //用法
            drugType: history.drugType, //药品剂型
            drugTypeArrayCheck: history.drugTypeStr?history.drugTypeStr.split(","):[],
            directId: history.directId,//督导人员
            aloneLive: history.aloneLive,//单独居住
            cloud: history.cloud, //通风环境
            smoke: history.smoke,// 是否抽烟
            nextSmoke: history.nextSmoke,//抽烟下次目标
            drink: history.drink,//饮酒
            nextDrink: history.nextDrink,//喝酒下次目标
            drugPlace: history.drugPlace,//取药地点
            drugTime: history.drugTime,// == null ? '' : Moment(history.drugTime).format("YYYY年MM月DD日"), //取药时间
            drugFrequency: history.drugFrequency,//取药频次
            nextDrugTime: history.nextDrugTime,// == null ? '' : Moment(history.nextDrugTime).format("YYYY年MM月DD日"), //下次取药时间
            cardSkill: history.cardSkill,//服药记录卡的填写是否掌握
            drugSkill: history.drugSkill,//服药方法及药品保存是否掌握
            issueSkill: history.issueSkill,//肺结核治疗疗程是否掌握
            irregularHarm: history.irregularHarm,//不规律服药危害是否掌握
            dealReact: history.dealReact,//不良反应是否掌握
            review: history.review,//复诊查痰是否掌握
            takingMedicine: history.takingMedicine,//外出期间服药是否掌握
            livingHabit: history.livingHabit,//生活习惯是否掌握
            contactCheck: history.contactCheck,//密切接触这检查是否掌握
            nextTime: history.nextTime,// == null ? '' : Moment(history.nextTime).format("YYYY年MM月DD日"), //下次随访时间
            doctorName: history.doctorName, //随访医生，
            pic: history.attachment ? history.attachment.split(",") : '', //上传照片
            sign: history.sign, //患者签名
            wrongReactDesc: this.state.detail.wrongReactDesc,   // 服药不良反应说明
            complicationDesc: this.state.detail.complicationDesc, // 并发症或合并症说明
            wrongReactTextInput: this.state.detail.wrongReact == 0 ? "none" : "block",
            complicationText: this.state.detail.complication == 0 ? "none" : "block",
            showDirectAI: history.showDirectAI
        })

        /*let attachment = history.attachment.split(",")
        this.setState({
            pic: attachment
        })*/

        this.updateSign = this.updateSign.bind(this)
        this.clickeImg = this.clickeImg.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.confirmRemoveImg = this.confirmRemoveImg.bind(this)
        this.pickerOnChange = this.pickerOnChange.bind(this)
        this.drugPickerOnChange = this.drugPickerOnChange.bind(this)
        this.nextDrugPickerOnChange = this.nextDrugPickerOnChange.bind(this)
        this.nextPickerOnChange = this.nextPickerOnChange.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.checkoutEventFirst = this.checkoutEventFirst.bind(this)
        this.handleUpdate = this.handleUpdate.bind(this)
        this.handleRemove = this.handleRemove.bind(this)
        this.askRemove = this.askRemove.bind(this)
        this.handleRemoveCancel = this.handleRemoveCancel.bind(this)
        this.checkoutDrugType = this.checkoutDrugType.bind(this)

    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        if (nextProps.updateSignRet !== this.props.updateSignRet) {
            if (nextProps.updateSignRet.code === 200) {
                this.setState({
                    sign: nextProps.updateSignRet.data.sign
                })
                Toast.show("更新签名成功！");
            }
            if (nextProps.updateSignRet.code === 201) {
                Toast.show("更新签名失败！" + nextProps.updateSignRet.msg);
            }
        }
    }


    // picker  onchange事件
    pickerOnChange(date, dateString) {
        this.setState({
            firstFollowUpTime: dateString,
            actualTimeYear: dateString.split("年")[0],
            actualTimeMonth: dateString.split("年")[1].split("月")[0],
            actualTimeDay: dateString.split("月")[1].split("日")[0],
            followUpTime: dateString.replace("年", "-").replace("月", "-").replace("日", ""),
        })
    }
    //取药时间change
    drugPickerOnChange(date, dateString) {
        this.setState({
            drugTimeStr: dateString,
            drugTimeYear: dateString.split("年")[0],
            drugTimeMonth: dateString.split("年")[1].split("月")[0],
            drugTimeDay: dateString.split("月")[1].split("日")[0],
            drugTime: dateString.replace("年", "-").replace("月", "-").replace("日", ""),
        })

    }

    //下次取药时间 change
    nextDrugPickerOnChange(date, dateString) {
        this.setState({
            nextDrugTimeStr: dateString,
            nextDrugTimeYear: dateString.split("年")[0],
            nextDrugTimeMonth: dateString.split("年")[1].split("月")[0],
            nextDrugTimeDay: dateString.split("月")[1].split("日")[0],
            nextDrugTime: dateString.replace("年", "-").replace("月", "-").replace("日", ""),
        })
    }

    //下次随访时间
    nextPickerOnChange(date, dateString) {
        this.setState({
            nextFollowUpTime: dateString,
            nextTimeYear: dateString.split("年")[0],
            nextTimeMonth: dateString.split("年")[1].split("月")[0],
            nextTimeDay: dateString.split("月")[1].split("日")[0],
            nextTime: dateString.replace("年", "-").replace("月", "-").replace("日", ""),
        })
    }


    handleInputChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
        console.log(event)

    }
    checkoutEventFirst(event) {
        if (event.target.checked == true) {
            this.state.firstArrayCheck.push(event.target.value);
        }
        if (event.target.checked == false) {
            let numberInit = this.state.firstArrayCheck.indexOf(event.target.value);
            this.state.firstArrayCheck.splice(numberInit, 1);
        }

        if (this.state.firstArrayCheck.indexOf("11") >= 0) {
            this.setState({
                hideOther: 0
            })
        } else {
            this.setState({
                hideOther: 1
            })
        }
    }

    checkoutDrugType(event) {
        console.log(event)
        console.log(this.state.drugTypeArrayCheck)
        if (event.target.checked == true) {
            this.state.drugTypeArrayCheck.push(event.target.value);
        }
        if (event.target.checked == false) {
            let numberInit = this.state.drugTypeArrayCheck.indexOf(event.target.value);
            this.state.drugTypeArrayCheck.splice(numberInit, 1);
        }
        this.setState({
            drugTypeStr: this.state.drugTypeArrayCheck.join(",")
        })
    }

    //点击提交按钮
    handleUpdate() {

        this.state.directType = this.state.directId
        if (this.state.directType === 0) {
            Toast.show("请选择督导人员！");
            return;
        }


        if (this.state.nextFollowUpTime === "") {
            Toast.show("请选择下次随访日期！");
            return;
        }

        //将数据打包成json,然后调用dispatch将数据发送出去
        const followUpInfo = {
            id: this.state.id,
            patientTreatTypeId: this.state.patientTreatTypeId,
            patientTypeId: this.state.patientTypeId,
            jhjCheckResultId: this.state.jhjCheckResultId,
            sputumCheckResultId: this.state.sputumCheckResultId,
            patientId: this.state.patientId,
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
            patientId: this.state.patientId,
            noticeId: this.state.noticeId,
            followupType: this.state.followupType, //随访地点
            followupTypeOther: this.state.followupTypeOther,
            issue: this.state.firstArrayCheck.join(","), //症状及体征
            issueOthers: this.state.issueOthers, //症状及体征
            useMethod: this.state.useMethod, //用法
            //drugType: this.state.drugType, //药品剂型
            drugTypeStr: this.state.drugTypeArrayCheck.join(","),
            directType: this.state.directType,//督导人员
            aloneLive: this.state.aloneLive,//单独居住
            cloud: this.state.cloud, //通风环境
            smoke: this.state.smoke,// 是否抽烟
            drink: this.state.drink,//饮酒
            smokeDay: this.state.smokeDay,
            drinkDay: this.state.drinkDay,
            nextSmoke: this.state.nextSmoke,//抽烟下次目标
            nextDrink: this.state.nextDrink,//喝酒下次目标
            drugPlace: this.state.drugPlace,//取药地点
            drugFrequency: this.state.drugFrequency,//取药频次
            cardSkill: this.state.cardSkill,//服药记录卡填写；1
            drugSkill: this.state.drugSkill,//服药方法及药品保存
            issueSkill: this.state.issueSkill,//肺结核治疗疗程
            irregularHarm: this.state.irregularHarm,//不规律服药危害
            dealReact: this.state.dealReact,//不良反应；1
            review: this.state.review,//复诊查痰
            takingMedicine: this.state.takingMedicine,//外出期间服药
            livingHabit: this.state.livingHabit,//生活习惯
            contactCheck: this.state.contactCheck,//密切接触这检查
            
            nextTimeYear: this.state.nextTimeYear,//下次随访年
            nextTimeMonth: this.state.nextTimeMonth,//下次随访月
            nextTimeDay: this.state.nextTimeDay,//下次随访日
            nextTime: this.state.nextTime,
            
            actualTimeYear: this.state.actualTimeYear,//实际随访年
            actualTimeMonth: this.state.actualTimeMonth,//实际随访月
            actualTimeDay: this.state.actualTimeDay,//实际随访日
            actualTime: this.state.followUpTime,

            drugTimeYear: this.state.drugTimeYear,//取药时间年
            drugTimeMonth: this.state.drugTimeMonth,//取药时间月
            drugTimeDay: this.state.drugTimeDay,//取药时间日
            drugTime: this.state.drugTime,

            suggestion: this.state.suggestion,

            nextDrugTimeYear: this.state.nextDrugTimeYear,//下次取药时间年
            nextDrugTimeMonth: this.state.nextDrugTimeMonth,//下次取药时间月
            nextDrugTimeDay: this.state.nextDrugTimeDay,//下次取药时间日
            nextDrugTime: this.state.nextDrugTime,

            doctorId: this.state.doctorId,
            doctorName: this.state.doctorName,
        };
        //提交首次随访
        const url = Api.getRootUrl() + '/api/followUp/editFirstFollowUp'
        let that = this;
        axios.post(url, followUpInfo).then(function (rsp) {
            console.log(rsp.data.code)
            if (rsp.data.code == 200) {
                Toast.info("提交成功!")
                that.handleNavBack();
            } else {
                Toast.info("提交失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("提交失败，请稍后再试！")
        })


    }
    handleRemove() {
        const followUpInfo = {
            id: this.state.id,
            userId: localStorage.getItem("userId"),
            token: localStorage.getItem("token"),
        }
        //删除首次随访
        const url = Api.getRootUrl() + '/api/followUp/remove'
        let that = this;
        axios.post(url, followUpInfo).then(function (rsp) {
            console.log(rsp.data.code)
            if (rsp.data.code == 200) {
                Toast.info("提交成功!")
                that.handleNavBack();
            } else {
                Toast.info("提交失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("提交失败，请稍后再试！")
        })
    }

    askRemove() {
        this.setState({
            removeVisible: true
        })
    }

    handleRemoveCancel() {
        this.setState({
            removeVisible: false
        })
    }


    //点击返回
    handleNavBack() {
        window.history.go(-1);
    }

    updateSign(signData) {
        const params = {
            id: this.state.id,
            patientId: this.state.patientId,
            sign: signData,
        }
        this.props.updatePatientSign(params);
    }

    clickeImg(imgUrl) {

        this.setState({
            open: true,
            removeImg: imgUrl
        })
    }

    handleClose() {
        this.setState({
            open: false
        })
    }

    confirmRemoveImg() {
        this.setState({
            open: false
        })

        let imgUrl = this.state.removeImg

        //const url = 'http://localhost:8080/api/followUp/removeImg'

        const url = '/api/followUp/removeImg'
        let params = { id: this.state.detail?.id, pic: imgUrl }
        console.log(this.state.pic)

        let pics = this.state.pic.filter(function (item) {
            return item !== imgUrl;
        })
        this.setState({
            pic: pics
        })

        axios.post(url, params).then(function (rsp) {
            console.log(rsp)
            if (rsp.data.code === 200) {

            } else {
                Toast.info("提交失败!" + rsp.data.msg)
            }
        }).catch(function (err) {
            console.log(err)
            Toast.info("提交失败，请稍后再试！")
        })
    }

    render() {
        const { classes } = this.props;
        //console.log(this.state.issues)
        //console.log(this.state.issue)


        var that = this;
        let pics = '';

        let fileList = new Array();

        //上传
        const uploadFile = {
            name: 'file',
            action: '/api/uploadFile',
            multiple: false,
            beforeUpload: (file) => {
                const isPNG = file.type === 'image/png';
                const isJPG = file.type === 'image/jpeg';
                if (!isPNG && !isJPG) {
                  message.error(`只支持PNG和JPG格式！`);
                  return false;
                }
                return isPNG || isJPG || Upload.LIST_IGNORE;
            },
            onChange(info) {
                console.log(pics)
                console.log(info)
                if (info.file.status !== 'uploading') {
                }
                if (info.file.status === 'done') {
                    message.success(`${info.file.name} 上传成功`);
                    (that.state.pic || []).map((item, index) => {
                        fileList.push(item)
                    })
                    info.fileList.map((item, index) => {
                        if (fileList.indexOf(item.response.data) < 0) {
                            fileList.push(item.response.data)

                            let params = {
                                patientId: that.state.patientId,
                                imgUrl: item.response.data,
                            }
                            that.props.addFollowupImage(params)
                        }

                    })
                    that.setState({
                        pic: fileList
                    })


                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} 上传失败.`);
                }
                return pics;
            }
        };


        return (
            <div className={classes.root}>

                <Dialog
                    open={this.state.open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            确认删除该张照片吗？
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.confirmRemoveImg}>确认</Button>
                        <Button onClick={this.handleClose} autoFocus>
                            取消
                        </Button>
                    </DialogActions>
                </Dialog>

                <Grid item xs={12} className={classes.Patient}>
                    <img src={axxxx} alt="" className={classes.jiantou} onClick={this.handleNavBack}></img>
                    <Grid item xs={12} className={classes.headers}><h5 className={classes.huanzhe}>肺结核患者首次入户访视记录表</h5></Grid>
                </Grid>
                <Grid className={classes.roots}>
                    <Typography h5 noWrap className={classes.spacemargin}></Typography>
                    <Grid container justify="center" wrap="nowrap" spacing={2}>
                        <Typography className={classes.titleMain} h5 gutterBottom>
                            肺结核患者首次入户访视记录表
                        </Typography>
                    </Grid>
                    <Typography h5 noWrap className={classes.spacemargin}></Typography>
                    <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} gutterBottom>
                                姓名：<span className={classes.title}>{this.state.patientName}</span>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.titleBold} gutterBottom>
                                登记号：<span className={classes.title}>{this.state.patientNumber}</span>
                            </Typography>
                        </Grid>

                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="space-between" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                <Grid>首次访视时间：</Grid>
                                <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " placeholder="请选择日期"
                                    value={Moment(this.state.followUpTime)}
                                    allowClear={false} className="date-picker-border" defaultValue={Moment(this.state.followUpTime)}
                                    onChange={this.pickerOnChange} />
                            </Typography>
                        </Grid>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                访视地点：
                            </Typography>
                        </Grid>
                        <RadioGroup row name="followupType" value={this.state.followupType} onChange={this.handleInputChange}>
                            <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={this.state.followupType == 1} control={<StyledRadio />} label="门诊" />
                            <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={this.state.followupType == 2} control={<StyledRadio />} label="家庭" />
                            <BeautyFormControlLabel className={this.state.followupType === 4 ? classes.OptionDiv : classes.hideMe} value="4" checked={this.state.followupType === 4} control={<StyledRadio />} label="其他" />
                        </RadioGroup>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={0} className={this.state.followupType === 4 ? "" : classes.hideMe}>
                        <Grid item>
                            <Typography className={classes.titleBolds} gutterBottom>
                                其他：<Input name="followupTypeOther" value={this.state.followupTypeOther} className={classes.inputs}
                                    inputProps={{ 'aria-label': 'description' }} />
                            </Typography>
                        </Grid></Grid>
                    <hr className={classes.hr}></hr>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                诊断结果：
                            </Typography>
                        </Grid>
                        <RadioGroup row name="patientTypeId" value={this.state.patientTypeId} disabled >
                            <BeautyFormControlLabel className={classes.OptionDiv} value="5" checked={this.state.patientTypeId == 5} control={<StyledRadio />} label="肺结核" />
                            <BeautyFormControlLabel className={classes.OptionDiv} value="10" checked={this.state.patientTypeId == 10} control={<StyledRadio />} label="结核性胸膜炎" />
                            <BeautyFormControlLabel className={classes.OptionDiv} value="15" checked={this.state.patientTypeId == 15} control={<StyledRadio />} label="肺结核+结核性胸膜炎" />
                        </RadioGroup>
                    </Grid>
                    <hr className={classes.hr}></hr>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                患者类型：
                            </Typography>
                        </Grid>
                        <RadioGroup row name="patientTreatTypeId" value={this.state.patientTreatTypeId} disabled>
                            <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={this.state.patientTreatTypeId == 1} control={<StyledRadio />} label="初治" />
                            <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={this.state.patientTreatTypeId == 2} control={<StyledRadio />} label="复治" />
                        </RadioGroup>
                    </Grid>
                    <hr className={classes.hr}></hr>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                痰菌情况：
                            </Typography>
                        </Grid>
                        <RadioGroup row name="sputumCheckResultId" value={this.state.sputumCheckResultId} disabled>
                            <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={this.state.sputumCheckResultId == 1} control={<StyledRadio />} label="阳性" />
                            <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={this.state.sputumCheckResultId == 2} control={<StyledRadio />} label="阴性" />
                            <BeautyFormControlLabel className={classes.OptionDiv} value="3" checked={this.state.sputumCheckResultId == 3} control={<StyledRadio />} label="未查痰" />
                        </RadioGroup>
                    </Grid>
                    <hr className={classes.hr}></hr>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                耐药情况：
                            </Typography>
                        </Grid>
                        <RadioGroup row name="jhjCheckResultId" value={this.state.jhjCheckResultId} disabled>
                            <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={this.state.jhjCheckResultId == 1} control={<StyledRadio />} label="耐药" />
                            <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={this.state.jhjCheckResultId == 2} control={<StyledRadio />} label="非耐药" />
                            <BeautyFormControlLabel className={classes.OptionDiv} value="3" checked={this.state.jhjCheckResultId == 3} control={<StyledRadio />} label="未检测" />
                        </RadioGroup>

                    </Grid>
                    <hr className={classes.hr}></hr>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                症状及体征：
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                <RadioGroup row name="issue" value={this.state.issue} onChange={this.handleInputChange}>

                                    <BeautyFormControlLabel label="没有症状" checked={this.state.firstArrayCheck?.indexOf("0") != -1} onChange={this.checkoutEventFirst} control={<GreenCheckbox value="0" color="primary" />} />

                                    <BeautyFormControlLabel label="咳嗽咳痰" checked={this.state.firstArrayCheck?.indexOf("1") != -1} onChange={this.checkoutEventFirst} control={<GreenCheckbox value="1" color="primary" />} />

                                    <BeautyFormControlLabel label="低热盗汗" checked={this.state.firstArrayCheck?.indexOf("2") != -1} onChange={this.checkoutEventFirst} control={<GreenCheckbox value="2" color="primary" />} />

                                    <BeautyFormControlLabel label="咳血或血痰" checked={this.state.firstArrayCheck?.indexOf("3") != -1} onChange={this.checkoutEventFirst} control={<GreenCheckbox value="3" color="primary" />} />

                                    <BeautyFormControlLabel label="胸痛消瘦" checked={this.state.firstArrayCheck?.indexOf("4") != -1} onChange={this.checkoutEventFirst} control={<GreenCheckbox value="4" color="primary" />} />

                                    <BeautyFormControlLabel label="恶心纳差" checked={this.state.firstArrayCheck?.indexOf("5") != -1} onChange={this.checkoutEventFirst} control={<GreenCheckbox value="5" color="primary" />} />

                                    <BeautyFormControlLabel label="关节疼痛" checked={this.state.firstArrayCheck?.indexOf("6") != -1} onChange={this.checkoutEventFirst} control={<GreenCheckbox value="6" color="primary" />} />

                                    <BeautyFormControlLabel label="头痛失眠" checked={this.state.firstArrayCheck?.indexOf("7") != -1} onChange={this.checkoutEventFirst} control={<GreenCheckbox value="7" color="primary" />} />

                                    <BeautyFormControlLabel label="视物模糊" checked={this.state.firstArrayCheck?.indexOf("8") != -1} onChange={this.checkoutEventFirst} control={<GreenCheckbox value="8" color="primary" />} />

                                    <BeautyFormControlLabel label="皮肤瘙痒、皮疹" checked={this.state.firstArrayCheck?.indexOf("9") != -1} onChange={this.checkoutEventFirst} control={<GreenCheckbox value="9" color="primary" />} />

                                    <BeautyFormControlLabel label="耳鸣听力下降" checked={this.state.firstArrayCheck?.indexOf("10") != -1} onChange={this.checkoutEventFirst} control={<GreenCheckbox value="10" color="primary" />} />

                                    <BeautyFormControlLabel label="其他" checked={this.state.firstArrayCheck?.indexOf("11") != -1} onChange={this.checkoutEventFirst} control={<GreenCheckbox value="11" color="primary" />} />

                                </RadioGroup>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                其他：<br />
                                <Input name="issueOthers" value={this.state.issueOthers} className={classes.inputs}
                                    onChange={this.handleInputChange} inputProps={{ 'aria-label': 'description' }} disabled />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="space-between" direction="column" wrap="nowrap" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                用药情况：
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                化疗方案：<span className={classes.span}>{this.state.resistMethod}</span>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                用 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;法：
                            </Typography>
                        </Grid>

                        <RadioGroup row name="useMethod" value={this.state.useMethod} onChange={this.handleInputChange} >
                            <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={this.state.useMethod == 1} control={<StyledRadio />} label="每日" />
                            <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={this.state.useMethod == 2} control={<StyledRadio />} label="间歇" />
                        </RadioGroup>

                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                药品剂型：
                            </Typography>
                        </Grid>
                        <RadioGroup row name="drugTypeStr" value={this.state.drugTypeStr} onChange={this.handleInputChange} >
                            <BeautyFormControlLabel label="固定剂量复合制剂" checked={this.state.drugTypeArrayCheck?.indexOf("1") != -1} onChange={this.checkoutDrugType} control={<GreenCheckbox value="1" color="primary" />} />
                            <BeautyFormControlLabel label="散装药" checked={this.state.drugTypeArrayCheck?.indexOf("2") != -1} onChange={this.checkoutDrugType} control={<GreenCheckbox value="2" color="primary" />} />
                            <BeautyFormControlLabel label="板式组合药" checked={this.state.drugTypeArrayCheck?.indexOf("3") != -1} onChange={this.checkoutDrugType} control={<GreenCheckbox value="3" color="primary" />} />
                            <BeautyFormControlLabel label="注射剂" checked={this.state.drugTypeArrayCheck?.indexOf("4") != -1} onChange={this.checkoutDrugType} control={<GreenCheckbox value="4" color="primary" />} />
                            {/* 
                            <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={this.state.drugType == 1} control={<StyledRadio />} label="固定剂量复合制剂" />
                            <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={this.state.drugType == 2} control={<StyledRadio />} label="散装药" />
                            <BeautyFormControlLabel className={classes.OptionDiv} value="3" checked={this.state.drugType == 3} control={<StyledRadio />} label="板式组合药" />
                            <BeautyFormControlLabel className={classes.OptionDiv} value="4" checked={this.state.drugType == 4} control={<StyledRadio />} label="注射剂" />
                            <BeautyFormControlLabel className={classes.OptionDiv} value="24" checked={this.state.drugType == 24} control={<StyledRadio />} label="散装药+注射剂" />
                            */}
                        </RadioGroup>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                督导人员：
                            </Typography>
                        </Grid>
                        <RadioGroup row name="directId" value={this.state.directId} onChange={this.handleInputChange} >
                            <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={this.state.directId == 1} control={<StyledRadio />} label="医生" />
                            <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={this.state.directId == 2} control={<StyledRadio />} label="家属" />
                            <BeautyFormControlLabel className={classes.OptionDiv} value="3" checked={this.state.directId == 3} control={<StyledRadio />} label="自服药" />
                            <BeautyFormControlLabel className={classes.OptionDiv} value="4" checked={this.state.directId == 4} control={<StyledRadio />} label="志愿者" />
                            <BeautyFormControlLabel className={this.state.showDirectAI == 1?classes.OptionDiv:classes.hideMe} value="5" checked={this.state.directId == 5} control={<StyledRadio />} label="智能工具" />
                        </RadioGroup>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                家庭居住环境评估：
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                单独的居室：
                            </Typography>
                        </Grid>
                        <RadioGroup row name="aloneLive" value={this.state.aloneLive} onChange={this.handleInputChange} >
                            <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={this.state.aloneLive == 1} control={<StyledRadio />} label="是" />
                            <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={this.state.aloneLive == 2} control={<StyledRadio />} label="否" />
                        </RadioGroup>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                通风情况：
                            </Typography>
                        </Grid>
                        <RadioGroup row name="cloud" value={this.state.cloud} onChange={this.handleInputChange} >
                            <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={this.state.cloud == 1} control={<StyledRadio />} label="良好" />
                            <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={this.state.cloud == 2} control={<StyledRadio />} label="一般" />
                            <BeautyFormControlLabel className={classes.OptionDiv} value="3" checked={this.state.cloud == 3} control={<StyledRadio />} label="差" />
                        </RadioGroup>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                生活方式评估：
                            </Typography>
                        </Grid> </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                吸烟：目前:<Input name="smoke" value={this.state.smoke} className={classes.input} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange}
                                />支/天，下次目标:<Input name="nextSmoke" value={this.state.nextSmoke} className={classes.input} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />支/天
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                饮酒：目前:<Input name="drink" value={this.state.drink} className={classes.input} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange}
                                />两/天，下次目标:<Input name="nextDrink" value={this.state.nextDrink} className={classes.input} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />两/天
                            </Typography>
                        </Grid>
                    </Grid>

                    <hr className={classes.hr}></hr>
                    <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBold} color="textSecondary" gutterBottom>
                                健康教育及培训：
                            </Typography>
                        </Grid></Grid>
                    <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBolds} gutterBottom>
                                首次取药的地点，时间，频次和下次取药时间：
                            </Typography>
                        </Grid></Grid>
                    <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBolds} gutterBottom>
                                地点：<Input name="drugPlace" value={this.state.drugPlace} className={classes.inputs} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                            </Typography>
                        </Grid></Grid>
                    <Grid container justify="flex-start" wrap="nowrap" direction="column" spacing={0}>
                        <Grid item>
                            <Typography className={classes.titleBoldBox} gutterBottom>
                                <Grid>时间：</Grid>
                                <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " allowClear={false} placeholder="请选择日期" className="date-picker-border"
                                    value={Moment(this.state.drugTime)} onChange={this.drugPickerOnChange} />
                            </Typography>
                        </Grid></Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBoldss} color="textSecondary" gutterBottom>
                                今后取药频次：<Input name="drugFrequency" value={this.state.drugFrequency} className={classes.inputs} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                            </Typography>
                            <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                <Grid>下次取药时间：</Grid>
                                <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " allowClear={false}
                                    placeholder="请选择日期" className="date-picker-border"
                                    value={Moment(this.state.nextDrugTime)} onChange={this.nextDrugPickerOnChange} />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                服药记录卡的填写：
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.titleBold} gutterBottom>
                                <RadioGroup row name="cardSkill" value={this.state.cardSkill} onChange={this.handleInputChange}>
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={this.state.cardSkill == 1} control={<StyledRadio />} label="掌握" />
                                    <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={this.state.cardSkill == 2} control={<StyledRadio />} label="未掌握" />
                                </RadioGroup>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                服药方法及药品存放：
                            </Typography>
                        </Grid>
                        <Grid item>
                            <RadioGroup row name="drugSkill" value={this.state.drugSkill} onChange={this.handleInputChange}>
                                <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={this.state.drugSkill == 1} control={<StyledRadio />} label="掌握" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={this.state.drugSkill == 2} control={<StyledRadio />} label="未掌握" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                肺结核治疗疗程：
                            </Typography>
                        </Grid>
                        <Grid item>
                            <RadioGroup row name="issueSkill" value={this.state.issueSkill} onChange={this.handleInputChange}>
                                <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={this.state.issueSkill == 1} control={<StyledRadio />} label="掌握" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={this.state.issueSkill == 2} control={<StyledRadio />} label="未掌握" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                不规律服药危害：
                            </Typography>
                        </Grid>
                        <Grid item>
                            <RadioGroup row name="irregularHarm" value={this.state.irregularHarm} onChange={this.handleInputChange}>
                                <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={this.state.irregularHarm == 1} control={<StyledRadio />} label="掌握" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={this.state.irregularHarm == 2} control={<StyledRadio />} label="未掌握" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                服药后不良反应及处理：
                            </Typography>
                        </Grid>
                        <Grid item>
                            <RadioGroup row name="dealReact" value={this.state.dealReact} onChange={this.handleInputChange} >
                                <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={this.state.dealReact == 1} control={<StyledRadio />} label="掌握" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={this.state.dealReact == 2} control={<StyledRadio />} label="未掌握" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                治疗期间复诊查痰：
                            </Typography>
                        </Grid>
                        <Grid item>
                            <RadioGroup row name="review" value={this.state.review} onChange={this.handleInputChange}>
                                <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={this.state.review == 1} control={<StyledRadio />} label="掌握" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={this.state.review == 2} control={<StyledRadio />} label="未掌握" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                外出期间如何坚持服药：
                            </Typography>
                        </Grid>
                        <Grid item>
                            <RadioGroup row name="takingMedicine" value={this.state.takingMedicine} onChange={this.handleInputChange}>
                                <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={this.state.takingMedicine == 1} control={<StyledRadio />} label="掌握" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={this.state.takingMedicine == 2} control={<StyledRadio />} label="未掌握" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                生活习惯及注意事项：
                            </Typography>
                        </Grid>
                        <Grid item>
                            <RadioGroup row name="livingHabit" value={this.state.livingHabit} onChange={this.handleInputChange}>
                                <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={this.state.livingHabit == 1} control={<StyledRadio />} label="掌握" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={this.state.livingHabit == 2} control={<StyledRadio />} label="未掌握" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBolds} color="textSecondary" gutterBottom>
                                密切接触者检查：
                            </Typography>
                        </Grid>
                        <Grid item>
                            <RadioGroup row name="contactCheck" value={this.state.contactCheck} onChange={this.handleInputChange}>
                                <BeautyFormControlLabel className={classes.OptionDiv} value="1" checked={this.state.contactCheck == 1} control={<StyledRadio />} label="掌握" />
                                <BeautyFormControlLabel className={classes.OptionDiv} value="2" checked={this.state.contactCheck == 2} control={<StyledRadio />} label="未掌握" />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <hr className={classes.hr}></hr>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBoldBox} color="textSecondary" gutterBottom>
                                <Grid>下次随访时间：</Grid>
                                <DatePicker locale={locale} format="YYYY年MM月DD日" suffixIcon=" " allowClear={false}
                                    placeholder="请选择日期" className="date-picker-border"
                                    value={Moment(this.state.nextTime)} onChange={this.nextPickerOnChange} />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.doctor} color="textSecondary" gutterBottom>
                                管理医生：<span className={classes.title}></span>
                                <Input name="doctorName" value={this.state.doctorName} className={classes.inputName} inputProps={{ 'aria-label': 'description' }} onChange={this.handleInputChange} />
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Typography className={classes.photosDIV} color="textSecondary" gutterBottom>
                            上传照片：<Upload {...uploadFile} showUploadList={{ "showDownloadIcon": false }} name="file">
                                <Button style={{ display: "" }} className={classes.buttonsTwo}>
                                    <Icon type="upload" /> 添加照片
                                </Button>
                            </Upload>
                            {/*(this.state.pic || []).map((item,index) => {
                            return (
                                        <img style={{display:""}} key={index} justify="flex-start" src={item} onClick={()=>this.clickeImg(item)} className={classes.picSize}></img>
                                    )
                            })
                        */}

                            <ImageList rowHeight={180} className={classes.imageList}>
                                {(this.state.pic || []).map((item) => (
                                    <ImageListItem key={item}>
                                        <img src={item} alt={item.title} />
                                        <ImageListItemBar
                                            title={item.title}
                                            onClick={() => this.clickeImg(item)}
                                            subtitle={<span>点击删除</span>}
                                            actionIcon={
                                                <IconButton aria-label={`info about ${item.title}`} className={classes.icon}>
                                                    <DeleteOutlineIcon />
                                                </IconButton>
                                            }
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>

                        </Typography>
                    </Grid>

                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBoldDiv} color="textSecondary" gutterBottom>
                                患者签名：
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} >
                        <SignPadPlus ref='patientSign' onSave={this.updateSign} name="sign" clearButton="true" height={300} className={classes.SignaturePadDiv} />
                    </Grid>
                    <Grid container justify="flex-start" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Typography className={classes.titleBoldDiv} color="textSecondary" gutterBottom>
                                已签预览：<img src={this.state.sign} width={300} />
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container justify="center" wrap="nowrap" spacing={2}>
                        <Grid item xs={12} className={classes.buttonDiv}>
                            <Button variant="contained" color="secondary" className={classes.buttonUpdate}
                                onClick={this.handleUpdate}>
                                更新
                            </Button>
                            <Button variant="contained" color="secondary" className={classes.buttonRemove}
                                onClick={this.askRemove}>
                                删除
                            </Button>
                        </Grid>
                    </Grid>

                    <div style={{ height: '1.25rem' }}></div>
                </Grid>

                <ModalAnt title="删除确认"
                    visible={this.state.removeVisible}
                    afterClose={this.handleRemoveCancel}
                    onCancel={this.handleRemoveCancel}
                    destroyOnClose={true}
                    forceRender={true}
                    footer={[
                        <Button key="submit" onClick={this.handleRemove}>提交</Button>,
                        <Button key="back" onClick={this.handleRemoveCancel}>取消</Button>,
                    ]}
                >
                    <Grid item xs={10} className={classes.bindContent}>
                        <Grid className={classes.bindContentFlex}>
                            <label>是否进行删除？</label>
                        </Grid>
                    </Grid>
                </ModalAnt>

            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        updateSignRet: state.customReducer.updateFollowupSignRet,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addFollowupImage: (params) => {
            dispatch(commitAddFollowupImage(params))
        },
        updatePatientSign: (params) => {
            dispatch(updateFollowupSign(params))
        },
    }
}


//css region
const styles = theme => ({
    buttonDiv: {
        height: '16vw',
        justifyContent: 'space-around',
        alignItems: 'center',
        display: 'flex',
    },
    buttons: {
        margin: theme.spacing(2),
        fontSize: '4.8vW',
        width: '91vw',
        height: '12vw',
        background: 'linear-gradient(to right,#FF7F50, 50%, #FF8C00)',
    },
    buttonUpdate: {
        width: '25vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        marginTop: '1vw',
        height: '8vw',
        fontSize: '3.5vw',
        padding: 0,
    },
    buttonRemove: {
        width: '25vw',
        background: 'linear-gradient(to right,#FF7F50, 50%, #FF8C00)',
        marginTop: '1vw',
        height: '8vw',
        fontSize: '3.5vw',
        padding: 0,
    },
    // 时间组件样式
    titleBoldBox: {
        marginLeft: theme.spacing(0),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        marginBottom: theme.spacing(0),
        color: '#333333',
        display: 'flex'
    },

    pickersInputBorder: {
        border: 0,
    },

    timeContentBoxRight: {
        width: "60%"
    },

    textFieldContent: {
        width: '60vw'
    },

    /**************** 分割线 **************/
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        background: '#ffffff',
    },

    roots: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },

    Patient: {
        display: 'flex',
        alignItems: 'center',
        width: '100vw',
        height: '8vh',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
    },

    jiantou: {
        height: '4vw',
        float: 'left',
        marginLeft: '4vw'
    },

    headers: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '77vw',
        height: '8vh',
    },

    huanzhe: {
        color: '#ffffff',
        fontSize: '4vw',
        fontWeight: '500',
    },

    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },

    file: {
        display: 'none',
        visibility: 'hidden',
    },

    buttonsTwo: {
        width: '25vw',
        height: '8vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        fontSize: '3vw',
        marginBottom: 'theme.spacing(2)',

        margin: theme.spacing(1),
        position: 'relative',
        border: '1px solid #00C9E4',
        borderRadius: '5px',
        padding: '4px 12px',
        overflow: 'hidden',
        color: '#fff',
        textDecoration: 'none',
        lineHeight: '8vw'
    },

    buttons: {
        width: '95vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        marginBottom: '10vw',
        fontSize: '4.5vw',
        marginTop: '5vw'
    },

    hr: {
        height: '0.3vw',
        border: '1.3vw',
        borderBottom: '0.1vw solid #e6e6e6',

    },

    textField: {
        marginTop: theme.spacing(0),
        width: '90vw',
    },

    input: {
        margin: theme.spacing(0),
        width: '11vw'
    },

    inputs: {
        width: '90vw',
        marginBottom: theme.spacing(2),
    },

    rootDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
    },

    parge: {
        width: '100vw',
        height: '50vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '30vh'
    },

    title1: {
        color: '#000000',
        fontSize: '4vw',
        fontWeight: '500',
    },

    span: {
        float: 'right'
    },

    titleBold: {
        marginLeft: theme.spacing(0),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        marginBottom: theme.spacing(0),
        color: '#333333'
    },

    titleButton: {
        marginLeft: theme.spacing(10),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        color: '#333333'
    },

    titleBoldDiv: {
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        color: '#333333'
    },

    titleTime: {
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        marginBottom: theme.spacing(0),
        color: '#333333'
    },

    titleMain: {
        fontSize: '5vw',
        lineHeight: '8vw',
        color: '#333333',
        fontWeight: '700',
    },

    titleBolds: {
        marginBottom: 0,
        fontSize: '4.2vw',
        lineHeight: '8vw',
        color: '#333333'

    },

    title: {
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '500',
        color: '#333333'
    },

    margin: {
        margin: theme.spacing(2),
    },

    spacemargin: {
        margin: theme.spacing(2),

    },

    titleBoldss: {
        marginTop: theme.spacing(2),
        fontSize: '4.2vw',
        color: '#333333'

    },

    doctor: {
        fontSize: '4.2vw',
        lineHeight: '5vh',
        fontWeight: '700',
        color: '#333333'
    },

    inputName: {
        marginBottom: theme.spacing(1),
        fontWeight: '500',
        width: '67vw'
    },

    photosDIV: {
        marginBottom: theme.spacing(2),
        fontSize: '4.2vw',
        lineHeight: '8vw',
        fontWeight: '700',
        color: '#333333'
    },

    picSize: {
        float: 'left',
        width: '25%',
        boxSizing: 'border-box',
        padding: '2vw',
        minWidth: '8vw'
    },

    hideMe: {
        display: 'none'
    }

});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FirstFollowUpHistoryDetails));
