import React from 'react';
import logo from '../assets/images/yidudao.png';
import { connect } from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { patientLogin, wxBaseLogin, getLoginView } from '../redux/actions/patients/login-action';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import { purple } from '@material-ui/core/colors';
import yonghu from '../assets/images/yonghu.png';
import mima from '../assets/images/mima.png';
import Grid from '@material-ui/core/Grid';
import MainView from './MainView';
import { Route, Switch } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import Link from "@material-ui/core/Link/Link";
import registered from "./registered";
import Toast from "antd-mobile/lib/toast";
import Api from '../redux/api/api';
import { Spin } from 'antd';
import md5 from 'md5'
import TencentCaptcha from '@carpenter/react-tencent-captcha';
import Checkbox from '@material-ui/core/Checkbox';


class PhoneLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: '',
            loginDisabled: false,
            hrefUrl: '',
            loading: false,
            agreement:false
        };

        //绑定事件
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handlePasswdChange = this.handlePasswdChange.bind(this);
        this.handleAgreementChange = this.handleAgreementChange.bind(this);

        this.handleLoginClick = this.handleLoginClick.bind(this);
        this.captchaCallback = this.captchaCallback.bind(this);

    }

    onRefChild = ref => {
        this.captcha = ref;
    }

    captchaCallback = res => {
        console.log(res);

        if (res.ret !== 0 || res.ticket === undefined || res.ticket?.length < 8) {
            return;
        }
        let ts = new Date().getTime()
        let flag = this.inputCheck();
        const loginInfo = {
            loginName: this.state.login,
            password: this.state.passwd,
            type: 3,//1为医生 3为患者
            captcha: res,
            ts: ts,
            ticket: res.ticket,
            as: md5(this.state.passwd + ts + res.ticket)
        }
        if (flag) { this.props.login(loginInfo); }
    }

    // 销毁
    destroy = () => {
        this.captcha.destroy();
    }

    componentDidMount() {
        let weixinUrl = window.location.href;
        let wxOpenId = localStorage.getItem("openId");
        // 判断storage是否有openID
        // 判断患者端登录后返回的链接是否携带code
        if (weixinUrl.split('code').length > 1) {
            Toast.show("正在登录，请稍后...");
            let paramsArr = weixinUrl.split("?")[1].split("&");
            let loginParams = {};
            paramsArr.forEach(function (key, value) {
                loginParams[key.split("=")[0]] = key.split("=")[1];
            })
            this.props.wxBaseLoginEnd(loginParams);
        } else if (wxOpenId && wxOpenId !== null && wxOpenId !== undefined && wxOpenId !== 'null' && wxOpenId !== '') {
            let patientId = localStorage.getItem("patientId");
            this.props.getLoginView(patientId);
        }
    }

    //fired when props changed
    componentWillReceiveProps(nextProps) {
        // 判断用户密码是否正确
        if (nextProps.loginResult != this.props.loginResult) {
            /*if (nextProps.returnCode === 200) {
                //成功登录,则转到主页
                const path = `/patientIndex`;
                this.props.history.push(path);
            }*/
            if (nextProps.loginResult?.code === 201) {
                Toast.show("登陆错误!" + nextProps.loginResult.msg);
                return false;
            }
            if (nextProps.loginResult?.data?.url != undefined && nextProps.loginResult.data.url.length > 5) {
                window.location.href = nextProps.loginResult.data.url;
            }
        }
        // 点击登录后 弹出授权页面
        //if (nextProps.loginResult && nextProps.loginResult.url) {
        /*let appId = 'wx02a912ac78edd27a';
        let redirectUri = encodeURIComponent('http://wx.yidudao.cn/patient');
        let hrefUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize' +
           '?appid=' + appId +
           '&redirect_uri=' + redirectUri +
           '&response_type=code&scope=snsapi_base' +
           '&state=' + nextProps.loginResult.id +
           '&connect_redirect=1#wechat_redirect';
        // 患者端跳转授权页面
        window.location.href = hrefUrl;*/
        //window.location.href = nextProps.loginResult.url;
        //}
        // 判断二次登录后的openId是否一致
        if (nextProps.loginView != this.props.loginView && nextProps.loginView.openId != undefined && nextProps.loginView.openId != '') {
            let wxOpenId = localStorage.getItem("openId");
            if (nextProps.loginView.openId == wxOpenId) {
                console.log("loginView pass")
                this.props.history.replace(`/patientIndex`);
            } else {
                console.log("what~~");
                localStorage.clear();
                return false;
            }
        }

        if (nextProps.baseInfoResult != this.props.baseInfoResult) {
            if (nextProps.baseInfoResult != undefined && nextProps.baseInfoResult.data != undefined && nextProps.baseInfoResult.data?.wxOpenId != undefined && nextProps.baseInfoResult.code == 200) {
                console.log("baseInfoResult pass")
                this.props.history.replace('/patientIndex');
            }
            if (nextProps.baseInfoResult.code == 201) {
                localStorage.removeItem("userId");
                localStorage.removeItem("patientId");
                localStorage.removeItem("openId");
                Toast.show("登陆失败！" + nextProps.baseInfoResult.msg);
            }

        }

    }

    handleNameChange(event) {
        this.setState({
            login: event.target.value
        })
    }

    handlePasswdChange(event) {
        this.setState({
            passwd: event.target.value,
        })
    }

    handleAgreementChange(event){
        console.log(event)
        this.setState({
           agreement: this.state.agreement?false:true
        })
     }

    //触发登录点击事件
    handleLoginClick() {
        localStorage.removeItem("openId");
        localStorage.removeItem("userId");
        localStorage.removeItem("patientId");
        let flag = this.inputCheck();
        const loginInfo = {
            loginName: this.state.login,
            password: this.state.passwd,
            type: 3,//1为医生 3为患者
        }
        this.props.login(loginInfo);
        /*if (this.state.hrefUrl == '' || this.state.hrefUrl == undefined || this.state.hrefUrl == null) {
           // 请求登录接口 获取 id和url
           let initHeaders = new Headers();
           initHeaders.append('Accept', 'application/json, text/plain, *!/!*')
           initHeaders.append('Content-Type', 'application/json;charset=UTF-8')
           let url = Api.getRootUrl() + '/api/user/login';
           const init = {method: 'POST',headers: initHeaders,body: JSON.stringify(loginInfo)};
           fetch(url,init).then(res => res.json()).then(data =>{
              if (flag) { this.props.login(loginInfo);}
  
           }).catch(e => {return {error: e};})
        }*/
    }

    //非空检验
    inputCheck() {
        if (this.state.login == '') {
            Toast.show("用户名不能为空!");
            return false;
        }
        if (this.state.passwd == '') {
            Toast.show("密码不能为空!");
            return false;
        }
        if(!this.state.agreement) {Toast.show("请选择同意《隐私政策》!");return false;}
        return true;
    }



    //点击跳转患者端注册页面
    handRegistered() {
        const path = `${this.props.match.path}/registered`;
        this.props.history.push(path);
    }

    render() {
        const { classes } = this.props;
        return (
            <Switch>
                <Route path={`${this.props.match.path}/Login`} component={MainView} />
                <Route path={`${this.props.match.path}/registered`} component={registered} />


                <Grid className={classes.root}>
                    <Grid item justify="center" wrap="nowrap" spacing={3}>
                        <img src={logo} alt="" className={classes.tupian}></img>
                    </Grid>

                    <Grid>
                        <Typography className={classes.typoText} gutterBottom>
                            患者端
                        </Typography>
                    </Grid>

                    <Grid item justify="center" wrap="nowrap" spacing={2}>
                        <Grid className={classes.myno}>
                            <img src={yonghu} className={classes.email}></img>
                            <YddInput name="idcardNum" placeholder='请输入用户名' value={this.state.name} onChange={this.handleNameChange} />
                        </Grid>
                    </Grid>

                    <Grid item justify="center" wrap="nowrap" spacing={2}>
                        <Grid className={classes.myPassword}>
                            <img src={mima} className={classes.passwordpicture}></img>
                            <YddInput name="idcardNum" placeholder='请输入密码' type='password' value={this.state.passwd} onChange={this.handlePasswdChange} />
                        </Grid>
                    </Grid>

                    <Grid item justify="center" wrap="nowrap" spacing={2}>
                        <Grid className={classes.agreement}>
                            <Checkbox className={classes.agreementBox} checked={this.state.agreement} onChange={this.handleAgreementChange} name="agreement" color="primary" />
                            <Typography className={classes.agreementText}>我已阅读并同意《<a href="http://terms.yidudao.cn/agreement.html">隐私政策</a>》</Typography>
                        </Grid>
                    </Grid>


                    <Grid item justify="center" wrap="nowrap" spacing={2}>
                        <TencentCaptcha
                            appid="190688974"
                            callback={this.captchaCallback}
                            onRefChild={this.onRefChild}
                        >
                            <ColorButton onClick2={this.handleLoginClick} variant="contained" color="primary" className={classes.margin}>
                                登 &nbsp;&nbsp;  录
                            </ColorButton>
                        </TencentCaptcha>
                    </Grid>

                </Grid>
            </Switch>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        returnCode: state.patientLoginReducer.returnCode,
        loginResult: state.patientLoginReducer.loginResult,
        baseInfoResult: state.patientLoginReducer.baseInfoResult,
        loginView: state.patientLoginReducer.loginView
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (patientInfo) => {
            dispatch(patientLogin(patientInfo))
        },
        wxBaseLoginEnd: (baseInfo) => {
            dispatch(wxBaseLogin(baseInfo))
        },
        getLoginView: (patientId) => {
            dispatch(getLoginView(patientId))
        }
    }
}


//css region
const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100vw',
        height: '100vh',
        // marginTop: '3vw',
        alignItems: 'center',
        color: '#333333',
        backgroundColor: '#ffffff',

    },
    paper: {
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },

    warning: {
        marginTop: theme.spacing(68),
    },

    click: {
        color: '#00d5dd',
        fontSize: '3.5vw',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(32),
    },

    message: {
        display: 'flex',
        alignItems: 'center',
    },

    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    icon: {
        fontSize: 20,
    },


    tupian: {
        width: '40vw',
        marginBottom: '2vw',
        marginTop: '29vw',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },

    typoText: {
        fontSize: '4.5vw',
        marginBottom: '15vw',
        color: '#00d5dd',
        fontWeight: '550',
        letterSpacing: '1.5vw',
    },

    loginWayDiv: {
        display: 'flex',
        justifyContent: 'flex-start',
    },

    forgetPasswdDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    classNameLoding: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center'
    },

    myno: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1.5vw',
        borderRadius: '1vw',
        flexDirection: 'row',
        width: '85vw',
        height: '12vw',
        border: '0.6px solid #666666',
    },

    email: {
        width: '4.5vw',
        paddingLeft: '1vw',
        height: '5vw',
        marginRight: '2vw',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },

    userName: {
        width: '80vw',
        fontSize: '4vw',
        paddingLeft: '1vw',
        borderRadius: '0.3vw',
        color: '#666666',
        border: '0',
        outline: 'none',
        height: '11vw',
        backgroundColor: 'transparent',
        lineHeight: '11vw',
    },

    myPassword: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '3vw',
        borderRadius: '1vw',
        flexDirection: 'row',
        width: '85vw',
        height: '12vw',
        border: '0.6px solid #666666',
        marginTop: '2vw'
    },

    passwordpicture: {
        width: '4.5vw',
        paddingLeft: '1vw',
        height: '5vw',
        marginRight: '2vw',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
    },

    passWord: {
        // width: '80vw',
        fontSize: '4vw',
        paddingLeft: '1vw',
        borderRadius: '0.3vw',
        color: '#a8adaa',
        border: '0',
        outline: 'none',
        height: '11vw',
        backgroundColor: 'transparent',
        lineHeight: '11vw',
    },

    agreement: {
        display: 'flex',
        alignItems: 'left',
        borderRadius: '1vw',
        flexDirection: 'row',
        width: '85vw',
     },
     agreementBox: {
        paddingLeft:'0',
     },
     agreementText: {
        marginTop:'2vw',
     },

    margin: {
        border: '0.6px solid #23d76e',
        width: '85vw',
        height: '11vw',
        fontSize: '5vw',
        marginTop: '10vw',
        borderRadius: '1vw',
        background: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        display: 'flex',
        lineHeight: '1vw',
    },

    modalRoot: {
        height: 300,
        flexGrow: 1,
        transform: 'translateZ(0)',
        '@media all and (-ms-high-contrast: none)': {
            display: 'none',
        },
    },

    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },

    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    }
});

const ColorButton = withStyles((theme) => ({
    root: {
        width: '40vw',
        height: '8vh',
        fontSize: '3.5vh',
        boxShadow: 'inset 0 0 4px #fff',
        color: theme.palette.getContrastText(purple[500]),
        backgroundColor: 'linear-gradient(to right,#00C9E4, 50%, #00DFD7)',
        '&:hover': {},
    },
}))(Button);

const YddInput = withStyles((theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            position: 'relative',
            fontSize: 15,
            width: '66vw',
            height: '9vw',
            borderBottom: '1 solid #666666',
            outline: 'none',
            backgroundColor: 'transparent',
            '&:focus': {
                borderColor: theme.palette.primary.main,
            },
        },
    }),
)(InputBase);
//end css region

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PhoneLogin));
